//button 通用的动态效果
@mixin buttonEffect {
  font-family: Poppins;
  font-weight: 500;
  font-style: normal;
  border-radius: 6px;
  border-style: solid;
  transition: all 0.5s;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  font-size: 20px;
  outline: none;
  text-align: center;
  justify-content: center;
  line-height: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    &::before {
      transform: translateX(0%);
    }
    .arrow {
      animation: arrow 0.5s;
      animation-fill-mode: forwards;
    }
    .arrowAr {
      animation: arrowAr 0.5s;
      animation-fill-mode: forwards;
    }
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 0px;
    top: 0px;
    width: 110%;
    height: 110%;
    transform: translateX(-110%);
    transition: transform 200ms ease 0s;
    will-change: transform;
  }
}

@mixin buttonPrimary {
  @include buttonEffect();
  background: #2762ff;
  color: #fff;
  border-color: #2762ff;
  &::before {
    background: var(--color, #0c40cf);
  }
}
@keyframes arrow {
  0% {
    opacity: 0;
    transform: translateX(-1px);
  }
  100% {
    opacity: 1;
    transform: translateX(5px);
  }
}
@keyframes arrowAr {
  0% {
    opacity: 0;
    transform: translateX(1px) scaleX(-1);
  }
  100% {
    opacity: 1;
    transform: translateX(-5px) scaleX(-1);
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.02);
  }
}

// flex布局 *****/
// 解决flex gap浏览器不兼容问题
@mixin gap($size) {
  margin-right: calc(0px - $size);
  & > * {
    margin-right: $size !important;
  }
}
