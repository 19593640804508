:root {
  background: #fff;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background: var(--Color-, #fff);
}

body {
  background: rgba(204, 208, 223, 0.3);
  min-height: 100vh;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
